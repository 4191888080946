import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import {
  CODES,
  getTrackingNameAndProperties,
  LAYOUT,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { REQUEST_TYPES, STATUS } from 'qonto/constants/requests';
import { subDaysToToday } from 'qonto/utils/date';

export const TABS = {
  PENDING: 'pending',
  TO_APPROVE: 'to-approve',
  TO_PAY: 'to-pay',
  COMPLETED: 'completed',
};

const EMPTY_RESULTS = '/lotties/empty-state/search-no-result.json';
const NO_REIMBURSEMENTS = '/lotties/empty-state/no-reimbursements-admin.json';

const name = 'reimbursements';

export const getEmptyStateConfig = (
  intl,
  { changePlanCtaCallback, addExpenseReportCtaCallback } = {}
) => {
  return {
    name,
    featureName: ['request_reimbursement_expense', 'request_reimbursement_milage'],
    featureNameCondition: 'or',
    variations: {
      ES_A3: {
        code: 'ES_A3',
        layout: LAYOUT.DISCOVER_PREVIEW,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.ACTIVATE }),
        badgeType: BADGE_TYPE.ACTIVATE,
        badgeText: intl.t('empty-states.system.activate.a3.badge'),
        variations: [
          {
            inputs: {
              activateAbilities: [`isBankless`],
            },
            output: {
              dummyData: getDummyData(intl, true),
              title: intl.t('expense-reports.empty-state.a3.title'),
              subtitle: intl.t('expense-reports.empty-state.a3.subtitle'),
              ctaText: intl.t('expense-reports.empty-state.a3.cta'),
              onClick: addExpenseReportCtaCallback,
            },
          },
          {
            inputs: {
              activateAbilities: [`canCreateExpenseReport`],
              copyAbilities: [`canReviewExpenseReport`],
              customInputs: { hasTeamManagement: true },
            },
            output: {
              dummyData: getDummyData(intl, true),
              title: intl.t('reimbursements.empty-state.a3.approval.title'),
              subtitle: intl.t('reimbursements.empty-state.a3.approval.subtitle'),
            },
          },
          {
            inputs: {
              activateAbilities: [`canCreateExpenseReport`],
              copyAbilities: [`canReviewExpenseReport`],
              customInputs: { hasTeamManagement: false },
            },
            output: {
              dummyData: getDummyData(intl, true),
              title: intl.t('reimbursements.empty-state.a3.approval.no-team.title'),
              subtitle: intl.t('reimbursements.empty-state.a3.approval.no-team.subtitle'),
            },
          },
          {
            inputs: {
              activateAbilities: [`canCreateExpenseReport`],
            },
            output: {
              dummyData: getDummyData(intl, false),
              title: intl.t('reimbursements.empty-state.a3.no-approval.title'),
              subtitle: intl.t('reimbursements.empty-state.a3.no-approval.subtitle'),
            },
          },
        ],
      },
      ES_I1: {
        code: 'ES_I1',
        layout: LAYOUT.INFORM,
        lottieSrc: NO_REIMBURSEMENTS,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            inputs: {
              customInputs: {
                tab: TABS.TO_APPROVE,
                hasTeamManagement: true,
              },
            },
            output: {
              title: intl.t('reimbursements.empty-state.i1.to-approve.title'),
              subtitle: intl.t('reimbursements.empty-state.i1.to-approve.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.TO_APPROVE,
                hasTeamManagement: false,
              },
            },
            output: {
              title: intl.t('reimbursements.empty-state.i1.to-approve.title'),
              subtitle: intl.t('reimbursements.empty-state.i1.to-approve.no-team.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.TO_PAY,
                hasTeamManagement: true,
              },
            },
            output: {
              title: intl.t('reimbursements.empty-state.i1.to-pay.title'),
              subtitle: intl.t('reimbursements.empty-state.i1.to-pay.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.TO_PAY,
                hasTeamManagement: false,
              },
            },
            output: {
              title: intl.t('reimbursements.empty-state.i1.to-pay.title'),
              subtitle: intl.t('reimbursements.empty-state.i1.to-pay.no-team.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.PENDING,
                hasTeamManagement: true,
              },
            },
            output: {
              title: intl.t('reimbursements.empty-state.i1.to-approve.title'),
              subtitle: intl.t('reimbursements.empty-state.i1.to-approve.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.PENDING,
                hasTeamManagement: false,
              },
            },
            output: {
              title: intl.t('reimbursements.empty-state.i1.to-approve.title'),
              subtitle: intl.t('reimbursements.empty-state.i1.to-approve.no-team.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: TABS.COMPLETED,
              },
            },
            output: {
              title: intl.t('reimbursements.empty-state.i1.completed.title'),
              subtitle: intl.t('reimbursements.empty-state.i1.completed.subtitle'),
            },
          },
        ],
      },
      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: EMPTY_RESULTS,
        tracking: getTrackingNameAndProperties({ name, type: TYPES.INFORM }),
        variations: [
          {
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
      ES_U1: {
        code: CODES.ES_U1,
        layout: LAYOUT.DISCOVER_PREVIEW,
        badgeType: BADGE_TYPE.SAVE,
        dummyData: getDummyData(intl, true),
        badgeText: intl.t('empty-states.system.upsell.u1.badge'),
        tracking: getTrackingNameAndProperties({ name, type: TYPES.UPSELL }),
        onClick: changePlanCtaCallback,
        variations: [
          {
            output: {
              title: intl.t('reimbursements.empty-state.u1.title'),
              subtitle: intl.t('reimbursements.empty-state.u1.subtitle'),
              ctaText: intl.t('empty-states.system.upsell.u1.cta'),
            },
          },
        ],
      },
      ES_U2: {
        code: CODES.ES_U2,
        layout: LAYOUT.DISCOVER_PREVIEW,
        badgeType: BADGE_TYPE.SAVE,
        dummyData: getDummyData(intl, true),
        badgeText: intl.t('empty-states.system.upsell.u1.badge'),
        tracking: getTrackingNameAndProperties({ name, type: TYPES.UPSELL }),
        onClick: changePlanCtaCallback,
        variations: [
          {
            output: {
              title: intl.t('reimbursements.empty-state.u2.title'),
              subtitle: intl.t('reimbursements.empty-state.u2.subtitle'),
              ctaText: intl.t('empty-states.system.upsell.u2.cta'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = (intl, isApprover = false) => {
  let requestType = REQUEST_TYPES.EXPENSE_REPORT;

  if (isApprover) {
    return {
      isApprover,
      activeTab: TABS.PENDING,
      items: [
        {
          initiator: {
            fullName: intl.t('empty-states.system.dummy-data.name-2'),
            team: { name: intl.t('empty-states.system.dummy-data.team-3') },
          },
          bankAccount: { safeName: intl.t('reimbursements.empty-state.a3.approval.account-name') },
          status: STATUS.PENDING,
          attachmentIds: [1],
          requestType,
          amount: { currency: 'EUR', value: 25 },
          createdAt: new Date(),
          note: intl.t('reimbursements.empty-state.a3.approval.dummy-data.row-1.notes'),
        },
        {
          initiator: {
            fullName: intl.t('empty-states.system.dummy-data.name-4'),
            team: { name: intl.t('empty-states.system.dummy-data.team-4') },
          },
          bankAccount: { safeName: intl.t('reimbursements.empty-state.a3.approval.account-name') },
          status: STATUS.PENDING,
          attachmentIds: [1],
          requestType,
          amount: { currency: 'EUR', value: 361.5 },
          createdAt: subDaysToToday(1),
          note: intl.t('reimbursements.empty-state.a3.approval.dummy-data.row-2.notes'),
        },
        {
          initiator: {
            fullName: intl.t('empty-states.system.dummy-data.name-11'),
            team: { name: intl.t('empty-states.system.dummy-data.team-2') },
          },
          bankAccount: { safeName: intl.t('reimbursements.empty-state.a3.approval.account-name') },
          status: STATUS.PENDING,
          attachmentIds: [1],
          requestType,
          amount: { currency: 'EUR', value: 361.5 },
          createdAt: subDaysToToday(2),
          note: intl.t('reimbursements.empty-state.a3.approval.dummy-data.row-3.notes'),
        },
        {
          initiator: {
            fullName: intl.t('empty-states.system.dummy-data.name-1'),
            team: { name: intl.t('empty-states.system.dummy-data.team-1') },
          },
          bankAccount: { safeName: intl.t('reimbursements.empty-state.a3.approval.account-name') },
          status: STATUS.PENDING,
          attachmentIds: [1],
          requestType,
          amount: { currency: 'EUR', value: 361.5 },
          createdAt: subDaysToToday(3),
          note: intl.t('reimbursements.empty-state.a3.approval.dummy-data.row-4.notes'),
        },
      ],
    };
  } else {
    return {
      isApprover,
      items: [
        {
          status: STATUS.APPROVED,
          displayedStatus: intl.t('request.status.approved'),
          requestType,
          amount: { currency: 'EUR', value: 25 },
          note: intl.t('reimbursements.empty-state.a3.no-approval.dummy-data.row-1.notes'),
        },
        {
          status: STATUS.CANCELLED,
          displayedStatus: intl.t('request.status.canceled'),
          requestType,
          amount: { currency: 'EUR', value: 25 },
          note: intl.t('reimbursements.empty-state.a3.no-approval.dummy-data.row-2.notes'),
        },
        {
          status: STATUS.PENDING,
          displayedStatus: intl.t('request.status.pending'),
          requestType,
          amount: { currency: 'EUR', value: 75 },
          note: intl.t('reimbursements.empty-state.a3.no-approval.dummy-data.row-3.notes'),
        },
        {
          status: STATUS.PENDING,
          displayedStatus: intl.t('request.status.pending'),
          requestType,
          amount: { currency: 'EUR', value: 50.25 },
          note: intl.t('reimbursements.empty-state.a3.no-approval.dummy-data.row-4.notes'),
        },
      ],
    };
  }
};
