export const PRICE_PLAN_LINEUPS = {
  SOLO: 'solo',
  TEAMS: 'teams',
};

export const PRICE_PLANT_TRAITS = {
  active: 'active',
  free_trial: 'free_trial',
  recommended: 'recommended',
  popular: 'popular',
};

export const ADDONS_DISCOVERABILITY_STORAGE_KEY = 'addons-discoverability-viewed';

export enum PricePlanGroupCodes {
  SoloBasic = 'solo_basic',
  SoloSmart = 'solo_smart',
  SoloPremium = 'solo_premium',
  TeamEssential = 'team_essential',
  TeamBusiness = 'team_business',
  TeamEnterprise = 'team_enterprise',
}
