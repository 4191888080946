export const CFT = Object.freeze({
  CASH_FLOW_MANAGEMENT: 'cash-flow-management',
  CUSTOMER_ENGAGEMENT: 'customer-engagement',
  FRAUD_COMPLIANCE: 'fraud-and-compliance',
} as const);

type CFTKey = keyof typeof CFT;

export const PIPE: Partial<Record<CFTKey, Record<string, string>>> = {
  CUSTOMER_ENGAGEMENT: {
    KYCB: 'KYCB',
  },
};
