import { Eligibility as ApiEligibility } from '@repo/domain-types/international-out';

const API_ELIGIBILITY_STATUS = ApiEligibility.Api.V1.Service.GetEligibilityResponse_Status;
const API_INELIGIBILITY_REASON = ApiEligibility.Api.V1.Service.GetEligibilityResponse_Reason;

export const ELIGIBILITY_STATUS = {
  ELIGIBLE: API_ELIGIBILITY_STATUS[API_ELIGIBILITY_STATUS.eligible] as 'eligible',
  INELIGIBLE: API_ELIGIBILITY_STATUS[API_ELIGIBILITY_STATUS.ineligible] as 'ineligible',
} as const;

export const UNKNOWN_ELIGIBILITY: EligibilityResponse = {
  status: API_ELIGIBILITY_STATUS[API_ELIGIBILITY_STATUS.ineligible] as 'ineligible',
  reason: API_INELIGIBILITY_REASON[API_INELIGIBILITY_REASON.unknown] as 'unknown',
} as const;

export const INELIGIBILITY_REASON = {
  FLEX_KYB_NOT_APPROVED: API_INELIGIBILITY_REASON[
    API_INELIGIBILITY_REASON.flex_kyb_not_approved
  ] as 'flex_kyb_not_approved',
  KYB_NOT_APPROVED: API_INELIGIBILITY_REASON[
    API_INELIGIBILITY_REASON.kyb_not_approved
  ] as 'kyb_not_approved',
  UNKNOWN: API_INELIGIBILITY_REASON[API_INELIGIBILITY_REASON.unknown] as 'unknown',
} as const;

export interface EligibilityResponse {
  status: (typeof ELIGIBILITY_STATUS)[keyof typeof ELIGIBILITY_STATUS];
  reason?: (typeof INELIGIBILITY_REASON)[keyof typeof INELIGIBILITY_REASON];
}
